import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import RlthousantBg from "../../assets/images/roadTesting/RlthousantBg.png";

import blueBox from '../../assets/images/home/footban2.jpg';

const OfferGreenBanner = () => {

    const styles = {
        greenBox: {
            background: `url(${RlthousantBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '20px 30px', marginTop: '40px', textAlign: 'center',
            '@media (max-width: 900px)': {
                padding: '30px',
                marginTop: '20px'
            },
        },

        greatOffersBut: {
            background: "var(--yellow)",
            border: "1px solid var(--yellow)",
            fontSize: "20px",
            fontWeight: "600",
            padding: '4px 20px',
            transition: '1s',
            color: '#000',
            borderRadius: '0px',
            "&:hover": {
                background: "#fff",
                color: "#fff",
                border: "1px solid var(--green)",
                boxShadow: "inset 300px 0px 0px var(--green)",
                transition: '1s'
            }
        },
        offersa: {
            cursor: 'pointer',
            color: 'var(--yellow) !important'
        },
        blueBox: {
            marginTop: '30px',
            background: `url(${blueBox}) no-repeat top center`,
            backgroundSize: 'cover', padding: '30px',
            borderRadius: '10px',
        },
        blueBoxText: {
            color: '#fff !important',
            background: '#00000054',
            padding: '10px 10px',
            borderRadius: '10px',
            textAlign: 'center',
            fontWeight: '400 !important',
        }

    }


    const navigate = useNavigate()
    return (
        <>

            <Container sx={{ pb: "20px", }}>


                <Grid sx={styles.greenBox}>
                    <Grid sx={{}}>

                        <Typography variant='h4' class="spec_offer">***Special Offers***</Typography>
                        <br />

                        <Typography variant="h2" sx={{ background: '#0063d3', fontSize: '14px !important', fontWeight: '400 !important', lineHeight: '1.6', color: '#fff !important', padding: '12px 0px', }}>
                            Valentine's Special  : <b style={{ fontSize: '25px' }}> 15% off</b>  on Behind the Wheel Lessons from  Feb 01 to Feb 28, 2025!
                        </Typography>


                        <Typography variant="h2" sx={{ background: '#0063d3', fontSize: '14px !important', fontWeight: '400 !important', lineHeight: '1.6', color: '#fff !important', padding: '12px 0px', }}>
                            Buy $124 Driver’s Education/ Joshua’s law at <b style={{ fontSize: '25px' }}>$95</b> with 6 or more hours of Behind the wheel Lessons
                        </Typography>
                        <Typography variant="h2" sx={{ background: '#0a73db', fontSize: '14px !important', fontWeight: '400 !important', lineHeight: '1.6', color: '#fff !important', padding: '12px 0px', }}>
                            Buy $95 Defensive Driving for <b style={{ fontSize: '25px' }}>FREE</b> with 12 or more hours of Behind the wheel Lessons
                        </Typography>
                        <Typography variant="h2" sx={{ background: '#278aed', fontSize: '14px !important', fontWeight: '400 !important', lineHeight: '1.6', color: '#fff !important', padding: '12px 0px', }}>
                            Buy $95 Defensive Driving at <b style={{ fontSize: '25px' }}>$75</b> with 2 to 10 hours of Behind the Wheel lessons, avail a minimum of 10% Insurance Discount that is Valid for 3 YEARS!
                        </Typography>

                        {/* <Typography variant="h2" sx={{ background: '#44a2ff', fontSize: '14px !important', fontWeight: '400 !important', lineHeight: '1.6', color: '#fff !important', padding: '12px 0px', }}>
                            Special - 30-hour drivers education class only $95 with any package!
                          
                        </Typography> */}


                        {/* <br /><br />/ */}
                        {/* <Typography component="a" onClick={() => navigate('/defensive-driving-course-remote', {
                            state: { number: 1 }
                        })} sx={{ padding: '4px 10px', background: '#000000', borderRadius: '4px', color: "#fff !important", cursor: 'pointer' }}>Click here to learn more about the Defensive Driving class!</Typography> */}
                        <br />
                        {/* <Typography component='a' sx={styles.offersa}>Click here to learn more about the defensive driving class!</Typography> */}
                        {/* <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px 0px 0px ', width: 'unset' }}>
                        <Grid>
                            <Button variant="outlined" className=''
                                sx={styles.greatOffersBut}
                            >
                                With GREAT OFFERS

                            </Button>
                        </Grid>
                        <Grid>
                            <Button variant="outlined" className='greenButAni' onClick={() => navigate('/schedule-driving-lessons-2/2')}>
                                GET NOW
                            </Button>
                        </Grid>

                    </Grid> */}

                        {/* <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <Grid>
                                <Button variant="outlined" className=''
                                    sx={{
                                        background: "var(--yellow)",
                                        border: "1px solid var(--yellow)",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        padding: '4px 20px',
                                        transition: '1s',
                                        color: '#000',
                                        borderRadius: '0px',

                                        "&:hover": {
                                            background: "#fff",
                                            color: "#fff",
                                            border: "1px solid var(--green)",
                                            boxShadow: "inset 300px 0px 0px var(--green)",
                                            transition: '1s'
                                        },
                                        marginTop: '40px'
                                    }}
                                >
                                    With GREAT OFFERS

                                </Button>
                            </Grid>
                            <Grid>
                                <Button variant="outlined" className='greenButAni' onClick={() => navigate('/schedule-driving-lessons-2',{
                                      state:{number:2}
                                    })}>
                                    GET NOW
                                </Button>
                            </Grid>
                        </Grid> */}

                    </Grid>
                </Grid>

                {/* <Grid sx={styles.blueBox}>
                <Typography variant='h6' sx={styles.blueBoxText}>Special -- 30-hour drivers education class only $95 with any package!
                    Behind-the-wheel training is in-person, of course. However, 30-hour drivers education class is offered LIVE via Zoom. All classes are approved by any County in State of Georgia.</Typography>
            </Grid> */}

            </Container>


        </>
    )
}

export default OfferGreenBanner