
import React from "react";
import { Box, Button, Typography, Grid, Chip, Stack, IconButton, useMediaQuery } from "@mui/material";
import webimg from '../../assets/Artboard3.jpg';
import mobimg from '../../assets/Artboard4.jpg'
import { IoMdCloseCircleOutline } from "react-icons/io";
import './Upsellingdialog.css'
const NewOffBanner = ({ handleClose, handleCloseOnly }) => {
    const isBelowMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const blinkingAnimation = {
        "@keyframes blink": {
            "0%": {
                borderColor: "blue",
                transform: "scale(1)" // Normal size
            },
            "50%": {
                borderColor: "transparent",
                transform: "scale(1.07)" // Bigger size
            },
            "100%": {
                borderColor: "white",
                transform: "scale(1)" // Normal size again
            },
        },
        border: "2px solid green", // Initial border color
        animation: "blink 1.5s infinite",
    };
    return (
        <Box
            sx={{
                backgroundImage: isBelowMd ? `url(${mobimg})` : `url(${webimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                color: "white",
                borderRadius: "2px",
                minHeight: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                position: "relative",
            }}
        >


            {/* Close Button */}
            <IconButton
                sx={{
                    fontSize: "40px",
                    color: "red",
                    background: "white",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    padding: "3px",
                    position: "absolute", // Absolute positioning
                    top: "10px", // Adjust top distance
                    right: "10px", // Adjust right distance
                }}
                onClick={handleCloseOnly || handleClose}
            >
                <IoMdCloseCircleOutline sx={{ fontSize: "40px" }} />
            </IconButton>

            {/* Discount Section */}
            <Stack
                spacing={2}
                sx={{
                    width: "100%",
                    maxWidth: "800px",
                    padding: { xs: "20px 20px", sm: "50px 20px 30px 70px" },
                }}
            >


                {
                    !isBelowMd ?
                        <Typography variant="h3" sx={{ padding: { xs: "37px 0px 0px 5px", }, fontWeight: 600, color: "white !important", fontSize: '25px !important', textAlign: 'center' }}>
                            15% OFF Behind-the-Wheel Driving Lessons
                        </Typography>

                        :
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        pt: 1,
                                        color: 'white !important',
                                        fontSize: '20px !important',
                                        backgroundColor: 'green',
                                        width: 'fit-content',
                                        padding: 1
                                    }}
                                >
                                    15% OFF
                                </Typography>
                            </Box>


                            <Typography sx={{ padding: { xs: "10px 0px 0px 15px", }, fontWeight: 600, color: "white !important", fontSize: '30px !important', textAlign: 'center' }}>
                                Behind-the-Wheel Driving Lessons
                            </Typography>


                        </>
                }


                <Typography variant="subtitle1" sx={{ mt: { xs: 0, md: 2 }, mb: 3, color: "white !important" }}>
                    Drive with Love: Exclusive Valentine's Deals Await You!
                </Typography>

                {/* Features */}
                <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: 3 }}>
                    <Grid item>
                        <Chip label="Free Pick-up & Drop-Off" color="primary" sx={{ background: "green", ...blinkingAnimation }} />
                    </Grid>
                    <Grid item>
                        <Chip label="Expert Training" color="primary" sx={{ background: "blue", ...blinkingAnimation }} />
                    </Grid>
                    <Grid item>
                        <Chip label="Flexible Scheduling" color="primary" sx={{ background: "green", ...blinkingAnimation }} />
                    </Grid>
                </Grid>

                {/* Offer Details */}
                <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="center" spacing={5} sx={{ mb: 3 }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1" sx={{ color: "white !important" }}>
                            Offer Valid<br />
                            <strong>Feb 01 – Feb 28, 2025</strong>
                        </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1" sx={{ color: "white !important" }}>
                            Don't Miss Out <br />
                            <strong>Limited Spots Available!</strong>
                        </Typography>
                    </Stack>
                </Stack>

                {/* Call to Action */}
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '30px' }}  >
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        sx={{
                            // display: "flex",
                            borderRadius: "30px",
                            border: "1px solid black",
                            paddingRight: " 10px",
                            textAlign: "center",
                            display: "flex",
                            // margin: "0 auto",
                            width: "fit-content",


                        }}
                    >
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            size="large"
                            sx={{
                                background: "#FFD700",
                                color: "black",
                                fontWeight: "bold",
                                "&:hover": { background: "#FFC700" },
                                borderRadius: "20px",
                                width: { xs: "100%", sm: "auto" },
                            }}
                        >
                            Book Your Lesson Now
                        </Button>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "white !important",
                                marginTop: { xs: "10px", sm: "0" },
                            }}
                        >
                            <strong> Call: 770-274-4223</strong>
                        </Typography>
                    </Stack>

                </div>
            </Stack>
        </Box>
    );
};

export default NewOffBanner;



