

import React, { useMemo } from 'react';
import { Slide, IconButton, DialogTitle, DialogContent, Typography, Stack, DialogActions, Checkbox, Button, FormControlLabel, Dialog, Box } from '@mui/material';

import { IoMdCloseCircleOutline } from "react-icons/io";


import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { Margin } from '@mui/icons-material';
import NewOffBanner from '../Drivinglessons/newOffBanner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});

const AnimatedDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        animation: `$slideIn 5s`,
        "@keyframes slideIn": {
            "0%": {
                transform: "translateX(-100%)",
            },
            "100%": {
                transform: "translateX(0)",
            },
        },
        padding: '0mm !important',  // Padding before the border in mm
        // margin: '10mm !important',
        // border: '2px solid black !important',
        // borderRadius: '8px',
    },

});



const HomeDialog = ({
    open,
    handleClose, handleCloseOnly }) => {


    const navigate = useNavigate()
    return (

        <AnimatedDialog
            open={open}
            onClose={handleCloseOnly}
            aria-labelledby="custom-dialog-title"
            TransitionComponent={Transition}
            transitionDuration={{ enter: 200, exit: 200 }}
            keepMounted
            fullWidth
            maxWidth={'lg'}
        >
            <div
            //  style={{ border: '2px solid #0063d3', borderRadius: '5px' }}
            >


                {/* <DialogTitle id="custom-dialog-title">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                        <Typography sx={{ color: 'red !important', textAlign: 'center', flexGrow: 1 }} variant="h5">
                            Limited Time Offer!
                        </Typography>

                        <IconButton sx={{ fontSize: '30px', color: 'black' }} variant="outlined" color='error' onClick={handleClose} >
                            <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} />
                        </IconButton>

                    </Stack>
                </DialogTitle> */}

                <DialogContent sx={{ padding: 0 }}>

                    <div class="box">
                        <NewOffBanner handleClose={handleClose} handleCloseOnly={handleCloseOnly} />
                    </div>
                </DialogContent>




            </div>
        </AnimatedDialog>
    );
};

export default HomeDialog;
