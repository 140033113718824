import React from 'react'
import { Box, Container, Grid, Typography, CardMedia, Stack, Button } from "@mui/material";

import PlaceIcon from '@mui/icons-material/Place';

import blueBox from '../../assets/images/home/footban2.jpg';

import Register from '../Register/Register'


import DrrlOffCanavas from '../DuiRiskReductionLocation/DrrlOffCanavas'

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useEffect, useState } from "react";
import axios from "axios";
import { base_Url } from "../BaseUrl/ApiUrl";
import GreenBanner from '../BaseUrl/GreenBanner';
import LocationCard from '../../components/LocationCard';
import QuestionDrawer from './QuestionDrawer';
import Banners from '../DuiRiskReductionZoom/Banners';
import { useNavigate } from 'react-router-dom';
import NewUserRegister from '../Register/NewUserRegister';
import { googleAnalyticsFunction } from '../googleanalytics/googleanalytics';



const styles = {
    packageBox: {
        boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px',
        '@media (max-width: 900px)': { marginTop: '40px', },
    },
    scheduleBox: {
        display: 'flex',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            gap: '20px',
        },
    },

    blueBox: {
        marginTop: '30px',
        background: `url(${blueBox}) no-repeat top center`,
        backgroundSize: 'cover', padding: '30px',
        borderRadius: '10px',
    },
    blueBox1: {
        marginTop: '30px',
        // background: `url(${blueBox}) no-repeat top center`,
        backgroundSize: 'cover', padding: '30px',
        borderRadius: '10px',
        backgroundColor: '#0063d3'
    },

    blueBoxText: {
        color: '#fff !important',
        background: '#00000054',
        padding: '10px 10px',
        borderRadius: '10px',
        textAlign: 'center',
        fontWeight: '400 !important',
    }
}


const programsData = [
    {
        prmTop1: "Starting",
        prmTop2: "$360",
        prmTittle: "DUI Risk Reduction class (Location)",
        prmTittleSub: "20 hours",
        prmDescription: "<b>$100</b> (for Assessment Questionnaire) <b>+ $260</b> (for class and book)",
        prmDescription1: "(Mandated by the Georgia Department of Driver Services (DDS).",
    },
];

const DrrlPackages = () => {

    const [registerStatus, setRegisterStatus] = useState(false)


    const [roadTesting, setRoadTesting] = useState([])
    const fetchPackages = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllCourse`, {
                type: ''
            })
            if (response.data.message === "success") {
                console.log('response', response?.data?.response)

                const course5 = response?.data?.response[1]
                setRoadTesting(course5)

            }
        } catch (error) {
            console.log(error)
        }
    }



    const [branchLocations, setBranchLocation] = useState([])
    const fetchLocations = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllBranches`, {
                type: ''
            })
            if (response.data.status) {
                console.log('response bbbbbb', response?.data?.response)
                setBranchLocation(response?.data?.response)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPackages()
        fetchLocations()
    }, [])
    console.log('roadTesting', roadTesting)


    console.log('branchLocations', branchLocations)




    const [finalData, setFinalData] = useState({})

    const [newUser, setNewUser] = useState(false)


    const fetchcheckPackageisExist = async (packageid) => {

        const isLoggedIn = localStorage.getItem('Student')

        try {
            const response = await axios.post(`${base_Url}/checkPackageisExist`, {
                packageid: packageid
            })
            if (response.data.message === 'success') {
                // console.log('response', response?.data?.response[0])

                const dataForGTM = response?.data?.response

                const courseData = dataForGTM?.packages?.map((val) => {
                    return {
                        productName: val?.packagename,
                        productPrice: val?.packageamount
                    }
                })

                googleAnalyticsFunction(dataForGTM, courseData)

                setFinalData(response?.data?.response)
                // setRegisterStatus(true)

                // if (isLoggedIn == null) {
                setNewUser(true)
                // }
                // else {
                //     setRegisterStatus(true)
                // }

            } else {
                setFinalData({})
            }
        } catch (error) {
            console.log(error)
        }
    }

    const navigate = useNavigate()



    console.log('finalData', finalData)

    const locations = [
        'East Cobb',
        'Lawrenceville',
        'Johns Creek',
        'Sandy Springs',
        'Alpharetta',
    ];


    const [questionOpen, setQuestionOpen] = useState(false)
    return (
        <>
            <Box sx={{ py: "50px", }}>
                <Container>
                    <Typography variant="h4" sx={{ pt: 2 }}> Package and Curriculum </Typography>


                    <Grid container spacing={2} mt={4}>

                        <Grid item md={7}  >
                            <Grid sx={{ border: '1px solid #E4E4E4', padding: '20px', borderRadius: '10px' }}>
                                {/* <Typography variant='h2' sx={{ mb: 2, }}>Curriculum</Typography> */}

                                <Grid sx={{ display: 'flex', gap: '20px', flexDirection: 'column', mb: 2 }}>
                                    <Grid sx={{ background: '#EFF7FF', padding: '20px' }}>
                                        <Typography mb="10px" variant='h2'>NEEDS Assessment Questionnaire—$100</Typography>
                                        <Typography component='f14' sx={{ fontWeight: '400 !important', }}>● Georgia Department of Driver Services (DDS) requires that you complete this questionnaire prior to the start of your class. </Typography><br />
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● A 20-30 minutes test which can be completed from the comfort of your home.</Typography><br />
                                        {/* <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● Link to the questionnaire will be sent to the email provided during purchase.</Typography> */}
                                    </Grid>

                                    <Grid item md={5} sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }}>
                                        {roadTesting?.courseDetails?.map((program, index) => (
                                            <>

                                                <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                                        <Grid className="prgrm_box_line"></Grid>

                                                        {/* <Grid className="prgrm_top_con">
                                                            <Typography sx={{ color: "#fff !important" }}>
                                                                Starting
                                                            </Typography>
                                                            <Typography
                                                                variant="h3"
                                                                sx={{ color: "#fff !important" }}
                                                            >
                                                                ${program.packageamount}
                                                            </Typography>
                                                        </Grid> */}

                                                        <Grid className="prgrm_img" style={{ padding: "10px" }} >
                                                            <CardMedia
                                                                className=""
                                                                component="img"
                                                                height="140px"
                                                                width="100%"
                                                                alt="program Image"
                                                                src={roadTesting.productimage}
                                                                sx={{ objectFit: 'cover' }}
                                                            />
                                                        </Grid>

                                                        {/* <Grid className="prgrm_con"> */}

                                                        <Grid className="prgrm_txt">
                                                            <Grid className="" style={{ padding: "10px 20px 0px" }}>
                                                                <Typography
                                                                    variant="h4"
                                                                    sx={{
                                                                        fontSize: "18px !important",
                                                                        margin: "0px 0px 16px",
                                                                    }}
                                                                >
                                                                    {program.packagename}
                                                                </Typography>

                                                                <Typography
                                                                    variant="h3"
                                                                // sx={{ color: "#fff !important" }}
                                                                >
                                                                    ${program.packageamount}
                                                                </Typography>

                                                                {/* <Typography
                                                                    variant="h4"
                                                                    sx={{
                                                                        fontSize: "18px !important",
                                                                        margin: "0px 0px 16px",
                                                                        fontWeight: "600 !important",
                                                                        color: "var(--primary) !important"
                                                                    }}
                                                                >
                                                                    {program.duration} Hrs
                                                                </Typography> */}

                                                                {/* 
                                                                <Typography sx={{ fontSize: "12px !important" }}>
                                                                    {roadTesting.description}
                                                                </Typography> */}
                                                            </Grid>

                                                            {/* <Grid onClick={() => setQuestionOpen(true)} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                                <a className="btns btn_1 hvr-bounce-to-right">
                                                                    GET NOW
                                                                </a>
                                                            </Grid> */}

                                                            <Stack sx={{ padding: '10px' }}>
                                                                <Button
                                                                    onClick={() => setQuestionOpen(true)}
                                                                    variant="contained"
                                                                    sx={{
                                                                        margin: '0 0 10px 10px',
                                                                        padding: '10px 20px',
                                                                        fontSize: '17px',
                                                                        '&:hover': {
                                                                            backgroundColor: 'black',
                                                                            color: 'white'
                                                                        }
                                                                    }}
                                                                >REGISTER</Button>
                                                            </Stack>

                                                        </Grid>
                                                        {/* </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <NewUserRegister
                                                    openStatus={newUser}
                                                    setRegisterStatus={setNewUser}
                                                    finalData={finalData}
                                                />



                                                <QuestionDrawer
                                                    open={questionOpen}
                                                    setOpen={setQuestionOpen}
                                                    fetchcheckPackageisExist={fetchcheckPackageisExist}
                                                    packageid={[program.packageid]}
                                                    navigateto={() => {
                                                        navigate(`/dui-risk-reduction-course-remote`, {
                                                            state: { number: 2 }
                                                        })
                                                    }}
                                                    route={''}
                                                    coursename={' DUI / Risk Reduction (zoom)'}

                                                />

                                                {/* <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                        <a className="btns btn_1 hvr-bounce-to-right">
                                            GET NOW
                                        </a>
                                    </Grid> */}
                                            </>

                                        ))}

                                    </Grid>

                                    <Grid sx={{ background: '#F2FFF9', padding: '20px' }}>
                                        <Typography mb="10px" variant='h2'>20-hour DUI/Risk Reduction Class—$260</Typography>
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● After taking the NEEDS assessment, you must complete a 20-hour DUI/Risk Reduction course.  </Typography><br />


                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● The 20-hour course must be taken consecutively. Weekday and Weekend Class schedules available and can be taken remotely via Live ZOOM or In-person.</Typography>
                                    </Grid>
                                </Grid>
                                <Typography sx={{ mb: 2 }}>
                                    Total fee for 20-hour DUI Risk Reduction class is $360 ($100 for Assessment Questionnaire + $260 for class and book). Mandated by the Georgia Department of Driver Services (DDS).
                                </Typography>

                                <div style={{ border: '1px solid #E4E4E4', padding: '20px', borderRadius: '10px', }}>


                                    <Typography component="f18w600" sx={{ mb: 2 }}>Class Schedules</Typography>
                                    <Grid sx={styles.scheduleBox}>

                                        <Grid sx={{ width: '100%', mt: 2 }}>
                                            <Typography marginBottom="20px" component="f18w600">Weekdays (Tue – Thurs)</Typography>
                                            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '10px 0px', mt: '10px', }}>
                                                <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Tuesday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>10:00 am - 7:00 pm</Typography></Grid>
                                                <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Wednesday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>10:00 am - 7:00 pm</Typography></Grid>
                                                <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Thursday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>10:00 am - 2:00 pm</Typography></Grid>

                                            </Grid>
                                        </Grid>

                                        <Grid sx={{ width: '100%', mt: 2 }}>
                                            <Typography mb="10px" component="f18w600">Weekends (Fri – Sun)</Typography>
                                            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '10px 0px', mt: '10px', width: '100%', }}>
                                                <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Friday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>6:00 pm - 10:00 pm</Typography></Grid>
                                                <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Saturday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:00 am - 6:00 pm</Typography></Grid>
                                                <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Sunday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:00 am - 6:00 pm</Typography></Grid>
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                </div>
                                <Typography sx={{ marginTop: "10px" }}>
                                    Certificates from the Georgia Department of Driver Services will be Emailed the next day by 05:30 pm from 1 Act Driving Schools.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item md={3.5} sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}>
                            {roadTesting?.courseDetails?.map((program, index) => (
                                <>

                                    <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                        <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                            <Grid className="prgrm_box_line"></Grid>

                                            {/* <Grid className="prgrm_top_con">
                                                <Typography sx={{ color: "#fff !important" }}>
                                                    Starting
                                                </Typography>
                                                <Typography
                                                    variant="h3"
                                                    sx={{ color: "#fff !important" }}
                                                >
                                                    ${program.packageamount}
                                                </Typography>
                                            </Grid> */}

                                            <Grid className="prgrm_img" style={{ padding: "10px" }} >
                                                <CardMedia
                                                    className=""
                                                    component="img"
                                                    height="140px"
                                                    width="100%"
                                                    alt="program Image"
                                                    src={roadTesting.productimage}
                                                    sx={{ objectFit: 'cover' }}
                                                />
                                            </Grid>

                                            {/* <Grid className="prgrm_con"> */}

                                            <Grid className="prgrm_txt">
                                                <Grid className="" style={{ padding: "10px 20px 0px" }}>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            margin: "0px 0px 16px",
                                                        }}
                                                    >
                                                        {program.packagename}
                                                    </Typography>

                                                    <Typography
                                                        variant="h3"
                                                    // sx={{ color: "#fff !important" }}
                                                    >
                                                        ${program.packageamount}
                                                    </Typography>

                                                    {/* <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            margin: "0px 0px 16px",
                                                            fontWeight: "600 !important",
                                                            color: "var(--primary) !important"
                                                        }}
                                                    >
                                                        {program.duration} Hrs
                                                    </Typography> */}


                                                    {/* <Typography sx={{ fontSize: "12px !important" }}>
                                                        {roadTesting.description}
                                                    </Typography> */}
                                                </Grid>

                                                {/* <Grid onClick={() => setQuestionOpen(true)} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                    <a className="btns btn_1 hvr-bounce-to-right">
                                                        GET NOW
                                                    </a>
                                                </Grid> */}

                                                <Stack sx={{ padding: '10px' }}>
                                                    <Button
                                                        onClick={() => setQuestionOpen(true)}
                                                        variant="contained"
                                                        sx={{
                                                            margin: '20px 0 0 0',
                                                            padding: '10px 20px',
                                                            fontSize: '17px',
                                                            '&:hover': {
                                                                backgroundColor: 'black',
                                                                color: 'white'
                                                            }
                                                        }}
                                                    >REGISTER</Button>
                                                </Stack>

                                            </Grid>
                                            {/* </Grid> */}
                                        </Grid>
                                    </Grid>



                                    <QuestionDrawer
                                        open={questionOpen}
                                        setOpen={setQuestionOpen}
                                        fetchcheckPackageisExist={fetchcheckPackageisExist}
                                        packageid={[program.packageid]}
                                        navigateto={() => {
                                            navigate(`/dui-risk-reduction-course-remote`, {
                                                state: { number: 2 }
                                            })
                                        }}
                                        route={''}
                                        coursename={' DUI / Risk Reduction (zoom)'}
                                        courseid={2}
                                    />

                                    {/* <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                        <a className="btns btn_1 hvr-bounce-to-right">
                                            GET NOW
                                        </a>
                                    </Grid> */}
                                </>

                            ))}

                        </Grid>

                    </Grid>

                    <Register
                        openStatus={registerStatus}
                        setRegisterStatus={setRegisterStatus}
                        finalData={finalData}
                        classmode={'2'}
                    />




                    <Grid sx={styles.blueBox}>
                        <Typography variant='h6' sx={styles.blueBoxText}>
                            <b>PLEASE NOTE:</b>Certificate of Completion is the same whether you take the class virtually via Zoom or In-person at our Office.
                        </Typography>
                    </Grid>

                    {/* <Banners/> */}
                    <LocationCard id={2} />



                    {/* <Grid sx={styles.blueBox1}>
                        <Stack spacing={2}>
                            <Typography variant='h5' sx={{ color: '#fff !important' }}>Sessions in both English & Spanish </Typography>
                            <Typography variant='h5' sx={{ color: '#fff !important' }}>Available 7 days of the week </Typography>
                            <Typography variant='h5' sx={{ color: '#fff !important' }}>Confidential sessions</Typography>
                        </Stack>
                    </Grid> */}
                </Container>
            </Box>
        </>
    )
}

export default DrrlPackages
