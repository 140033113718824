import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Box, Button, Container, Grid, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';
import { googleAnalyticsFunction } from '../googleanalytics/googleanalytics';
import RoadTestingBannerBg from '../../assets/images/roadTesting/RoadTestingBanner.png'
// Lazy load non-essential components
const NewUserRegister = lazy(() => import('../Register/NewUserRegister'));

// Optimized styles
const styles = {
  rtBanner: {
    pt: "150px",
    pb: "50px",
    backgroundImage: `url(${RoadTestingBannerBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media (max-width: 900px)': {
      backgroundSize: 'contain',
    },
  },
  button: {
    mt: '20px',
    color: '#fff',
    borderColor: 'var(--yellow)',
    '&:hover': {
      backgroundColor: 'var(--yellow)',
    },
  },
};

const RtBanner = () => {
  const [roadTesting, setRoadTesting] = useState([]);
  const [loading, setLoading] = useState(true);
  const [registerStatus, setRegisterStatus] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [newUser, setNewUser] = useState(false);

  // Fetch packages with optimized loading
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, { type: '' });
      if (response.data.status) {
        const course5 = response?.data?.response[4];
        setRoadTesting(course5);
      }
    } catch (error) {
      console.error('Error fetching packages:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);
  const isStudentExist = JSON.parse(localStorage.getItem('Student'))
  // Fetch package existence with debounced calls
  const fetchcheckPackageisExist = async (packageid) => {
    const isLoggedIn = localStorage.getItem('Student');
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, { packageid, studentid: isStudentExist?.customerid || 0 });
      if (response.data.message === 'success') {
        const dataForGTM = response?.data?.response;

        // Map data for analytics
        const courseData = dataForGTM?.packages?.map((val) => ({
          productName: val?.packagename,
          productPrice: val?.packageamount,
        }));

        googleAnalyticsFunction(dataForGTM, courseData);
        setFinalData(response?.data?.response);
        setNewUser(true);
      } else {
        setFinalData({});
      }
    } catch (error) {
      console.error('Error checking package existence:', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ ...styles.rtBanner, textAlign: 'center', pt: '200px' }}>
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return (
    <Box sx={styles.rtBanner}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h5" sx={{ fontWeight: '100', lineHeight: 1.5, color: '#fff !important', mb: 2 }}>
              Building Road-ready Confidence
            </Typography>
            <Typography variant="h1" sx={{ color: 'var(--yellow) !important', fontWeight: 'bold' }}>
              ONSITE ROAD TESTING
            </Typography>
            <Button
              className='greenButAni'
              variant="outlined"
              sx={styles.button}
              onClick={() => fetchcheckPackageisExist([roadTesting?.courseDetails[0]?.packageid])}
            >
              Register Now
            </Button>

            <Suspense fallback={<CircularProgress color="inherit" />}>
              <NewUserRegister
                openStatus={newUser}
                setRegisterStatus={setNewUser}
                finalData={finalData}
                setFinalData={setFinalData}
              />
            </Suspense>
          </Grid>
          <Grid item md={6}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default RtBanner;
